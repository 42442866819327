// LandingApp.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import TenderSelectionPage from './TenderSelectionPage';
import LoginPage from './LoginPage';
import SubscriptionPage from './SubscriptionPage';
import SubscriptionCheck from './SubscriptionCheck';
import App from './App';
import AppState from './AppState';
import AppCentral from './AppCentral';
import ProtectedRoute from './ProtectedRoute'; // Import ProtectedRoute if needed
import AboutUs from './components/AboutUs'; // Import the About Us component
import Services from './components/Services'; // Import the Services component
import SubscriptionPricing from './components/SubscriptionPricing'; // Import the SubscriptionPricing component
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import RefundPolicy from './pages/RefundPolicy';
import PricingPolicy from './pages/PricingPolicy';
import ContactUs from './pages/ContactUs';
import MyProfile from './components/MyProfile';
import FavoriteTenders from './components/FavoriteTenders';
import Reports from './components/Reports';
import { UserProvider } from './UserContext'; // Adjust the path as needed
import ActivePlanPage from './components/ActivePlanPage';
import ProtectedRouteForLoggedInUsers from './ProtectedRouteForLoggedInUsers'; // Adjust the path if necessary


function LandingApp() {
  return (
    <UserProvider>
    <Router>
      <Routes>
        {/* Home Page - Default Route */}
        <Route path="/" element={<HomePage />} /> {/* New route for HomePage */}

        {/* Other routes */}
        <Route path="/about" element={<AboutUs />} />

        {/* Other routes */}
        <Route path="/services" element={<Services />} />

        {/* Other routes */}
        <Route path="/subscription-pricing" element={<SubscriptionPricing />} />

        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/pricing-policy" element={<PricingPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        {/* Other routes */}

        <Route
  path="/my-profile"
  element={
    <ProtectedRouteForLoggedInUsers>
      <MyProfile />
    </ProtectedRouteForLoggedInUsers>
  }
/>
<Route
  path="/active-plan"
  element={
    <ProtectedRouteForLoggedInUsers>
      <ActivePlanPage />
    </ProtectedRouteForLoggedInUsers>
  }
/>
<Route
  path="/favorite-tenders"
  element={
    <ProtectedRouteForLoggedInUsers>
      <FavoriteTenders />
    </ProtectedRouteForLoggedInUsers>
  }
/>
<Route
  path="/reports"
  element={
    <ProtectedRouteForLoggedInUsers>
      <Reports />
    </ProtectedRouteForLoggedInUsers>
  }
/>

        {/* Login Page */}
        <Route path="/login" element={<LoginPage />} />

        {/* Subscription Check */}
        <Route path="/subscription-check" element={<SubscriptionCheck />} />

        {/* Subscription Page */}
        <Route path="/subscription" element={<SubscriptionPage />} />

        {/* Tender Selection Page */}
        <Route
          path="/tender-selection"
          element={
            <ProtectedRoute>
              <TenderSelectionPage />
            </ProtectedRoute>
          }
        />

        {/* Route for GeM Bids */}
        < Route
          path="/gem-bids"
          element={
          <ProtectedRoute>
          <App />
          </ProtectedRoute>
         }
        />

        {/* Route for State CPPP */}
        <Route
          path="/state-cppp-tenders"
          element={
            <ProtectedRoute>
              <AppState />
            </ProtectedRoute>
          }
        />


        {/* Route for Central CPPP */}
        <Route
          path="/central-cppp-tenders"
          element={
            <ProtectedRoute>
              <AppCentral />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
    </UserProvider>  
  );
}

export default LandingApp;
