// src/components/HomePage.js
import React from 'react';
import Layout from './Layout'; // Import the Layout component
import ClientCarousel from './ClientCarousel'; // Import the carousel
import './HomePage.css';

function HomePage() {
    return (
        <Layout>
            <div className="home-page">
                <header className="hero-section">
                    <h1>Welcome to Tender Managers</h1>
                    <p>Your go-to platform for tender management and insights.</p>
                </header>
                
                {/* Add the carousel above the About section */}
                <ClientCarousel />

                <main className="content">
                    <section className="company-description">
                        <h2>About Our Company</h2>
                        <p>We provide comprehensive solutions for managing tenders, helping businesses streamline their processes and achieve better results. With our expertise, you can simplify your tender management and focus on what matters most.</p>
                    </section>
                    <section className="contact-us">
                        <h2>Contact Us</h2>
                        <p>Email: sales@gemmanagers.com</p>
                        <p>Phone: +91-6398896398</p>
                        
                    </section>
                </main>
            </div>
        </Layout>
    );
}

export default HomePage;
